import type { PaymentRecoveryStatus } from "@silexpert/core";
import { getCaarlStatusGroup, PaymentRecoveryTag } from "@silexpert/core";

const udsColors = getUdsColors();

export function useRecoveryComposable() {
  function getStatusMetadata(status: PaymentRecoveryStatus) {
    const statusGroup = getCaarlStatusGroup(status);
    switch (statusGroup) {
      case PaymentRecoveryTag.RECOVERED:
        return {
          icon: "done",
          spIcon: "done",
          label: "Recouvrées",
          iconLabel: "Recouvrement effectué",
          colorname: "success",
          color: udsColors.udsSuccess500,
        };
      case PaymentRecoveryTag.WAITING:
        return {
          icon: "accesstime",
          spIcon: "build",
          label: "En cours",
          iconLabel: "Recouvrement en cours",
          colorname: "fire",
          color: udsColors.udsFire500,
        };
      case PaymentRecoveryTag.NOT_RECOVERED:
        return {
          icon: "warning",
          spIcon: "close",
          label: "Non recouvrée",
          iconLabel: "Recouvrement clôturé",
          colorname: "error",
          color: udsColors.udsError500,
        };
      default:
        return {
          icon: "undefined",
          spIcon: "build",
          color: undefined,
          label: "Inconnue",
          colorname: undefined,
        };
    }
  }

  return {
    getStatusMetadata,
  };
}
